import PropTypes from "prop-types";
import React from "react";

import Elevation from "../components/elevation/Elevation";
import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function ElevationPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "API", "Elevation"]}
          location={location}
          metaDescription="Get elevation data anywhere on Earth for your website or mobile application with Jawg Maps."
          title="Elevation"
        />
        <Page>
          <Elevation />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

ElevationPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default ElevationPage;
